import { gsap } from "gsap";
import { ScrollTrigger} from "gsap/ScrollTrigger.js";
import { ScrollSmoother } from "gsap/ScrollSmoother.js";

let mm = gsap.matchMedia();
let vid = document.getElementById("myVid");

function playVid() {
    vid.play();
}

function pauseVid() {
    vid.pause();
} 

gsap.registerPlugin(ScrollTrigger, ScrollSmoother );

mm.add("(min-width: 100px)", () => {

  ScrollSmoother.create({
    smooth: 0.8,           // how long (in seconds) it takes to "catch up" to the native scroll position
    effects: true,         // looks for data-speed and data-lag attributes on elements
    normalizeScroll: true, 
    smoothTouch: false // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
  });

  vid.play();


});

// This is the rotation function not using it now.

// gsap.to(".gsr", {
//   scrollTrigger: {
//     trigger: ".gsr",
//     start: "bottom center",
//     toggleActions: "restart pause reverse pause",
//     // markers: true,
//   },
//   rotate: 360,
//   duration: 8
// });

vid.play();
